/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function GetURLParameter(sParam) {
  var sPageURL = window.location.search.substring(1);
  var sURLVariables = sPageURL.split('&');
  for (var i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1];
    }
  }
}

function toDate(dateStr) {
    var parts = dateStr.split("-");
    return new Date(parts[2], parts[0]-1, parts[1]);
}

jQuery(function() {
  //add target to main menu items
  jQuery('#menu-item-1732 a').attr('target','_blank');
  jQuery('#menu-item-21366 a').attr('target','_blank');

  //handle events on navigation menu
  var currentPath = window.location.pathname;
  if( currentPath.indexOf("order") > 0){
    jQuery("#menu-main-menu li.active").removeClass("active"); 
    jQuery("#menu-main-menu li:nth-child(1)").addClass("active"); 
  }
  if( currentPath.indexOf("menu") > 0){
    jQuery("#menu-main-menu li.active").removeClass("active"); 
    jQuery("#menu-main-menu li:nth-child(2)").addClass("active"); 
  }
  if( currentPath.indexOf("location") > 0){
    jQuery("#menu-main-menu li.active").removeClass("active"); 
    jQuery("#menu-main-menu li:nth-child(3)").addClass("active"); 
  }
  if( currentPath.indexOf("about") > 0 || currentPath.indexOf("books") > 0 || currentPath.indexOf("poetry") > 0 ||
    currentPath.indexOf("art") > 0 || currentPath.indexOf("peace-ball") > 0 ||
    currentPath.indexOf("sustainability") > 0
  ){
    jQuery("#menu-main-menu li.active").removeClass("active"); 
    jQuery("#menu-main-menu li:nth-child(4)").addClass("active"); 
  }
  if( currentPath.indexOf("blog") > 0 || currentPath.indexOf("mentions") > 0){
    jQuery("#menu-main-menu li.active").removeClass("active"); 
    jQuery("#menu-main-menu li:nth-child(5)").addClass("active"); 
  }
  if( currentPath.indexOf("events") > 0){
    jQuery("#menu-main-menu li.active").removeClass("active"); 
    jQuery("#menu-main-menu li:nth-child(6)").addClass("active"); 
  }
  if( currentPath.indexOf("audiobooks") > 0 || currentPath.indexOf("balance") > 0){
    jQuery("#menu-main-menu li.active").removeClass("active"); 
    jQuery("#menu-main-menu li:nth-child(7)").addClass("active"); 
  }
});




